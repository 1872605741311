body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: 0;
}
.blog-header {
  box-shadow: 0 0 8px 0px #00000082;
  background: #fff;
}
.header-logo img {
  width: 60px;
}
.back {
  color: #273969;
  text-decoration: none;
  font-size: 12px;
}
.call {
  color: #273969;
  font-size: 16px;
  font-weight: bold;
}
.back:hover {
  color: #273969;
}
.ques-bg {
  background: #273969;
  padding: 150px 150px;
  height: 100vh;
  position: relative;
}
.ques-section {
  padding: 0px 0;
}
.que-section {
  padding: 40px 0;
}
.que-section p {
  color: #fff;
  font-size: 18px;
  letter-spacing: 0.5px;
}
.que-section p span {
  font-size: 32px;
}
.que-bg {
  background: #fff;
  min-width: 60%;
  padding: 15px 50px;
  margin: 0 auto;
  min-height: 250px;
  border-radius: 5px;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.4);
}
.que-bg hr {
  margin: 10px 0;
}
.que-title {
  color: #526574;
  font-weight: bold;
  font-size: 24px;
  text-align: center;
  text-transform: capitalize;
}
.ques-title {
  color: #fff;
  font-weight: bold;
  font-size: 24px;
  text-align: center;
  margin-bottom: 25px;
}
.que-list {
  padding: 15px 0 15px 0;
  margin: 0;
}
.que-list li {
  list-style: none;
  display: block;
  margin: 0;
  padding: 7px 0;
  color: #283a6a;
  font-size: 16px;
}
.que-list li .fa-arrow-circle-o-right {
  padding-right: 10px;
  color: #c0abe8 !important;
  font-size: 16px;
}
.que-option {
  padding-top: 20px;
  padding-bottom: 10px;
}
.que-option .form-check-label {
  color: #526574;
  align-self: center;
}
.que-option .form-check-input:checked[type="radio"] {
  background-image: none;
}
.que-option .form-check-input {
  background: #f8f5ff;
  border: 1px solid #8860d0;
}
.que-option .form-check-input:checked {
  background: #8860d0;
  border-color: #8860d0;
}
.form-check-input:focus {
  box-shadow: none;
}
.small-tile {
  color: #8860d0 !important;
  font-size: 12px !important;
  text-align: center;
}
.que-option .box-flex {
  display: flex;
  align-items: center;
}
.que-option input[type="number"] {
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  appearance: textfield;
  font-size: 24px;
  width: 45px;
}

.que-option input[type="number"]::-webkit-inner-spin-button,
.que-option input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

.que-option .number-input {
  display: inline-flex;
  align-items: center;
}
.que-option .number-input * {
  border: 1px solid #8860d0;
  background: #f8f5ff;
  color: #8860d0;
}
.que-option .number-input,
.que-option .number-input * {
  box-sizing: border-box;
  margin: 0 13px;
  height: 45px;
  /*width: 50px;*/
  border-radius: 5px;
  text-align: center;
}
.que-option .number-input button {
  outline: none;
  -webkit-appearance: none;
  background-color: transparent;
  border: 1px solid #8860d0;
  color: #8860d0;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  cursor: pointer;
  margin: 0;
  border-radius: 5px;
  position: relative;
}
.custom-button {
  outline: none;
  -webkit-appearance: none;
  background-color: transparent;
  border: 1px solid #8860d0;
  color: #8860d0;
}
.custom-button:hover {
  -webkit-appearance: none;
  background-color: #8860d0;
  border: 1px solid #8860d0;
}
.que-option .form-control,
.form-control:focus {
  box-shadow: none;
  border-color: #8860d0;
  color: #8860d0;
  background: #f8f5ff;
}
.que-option .form-control::placeholder {
  color: #8860d0;
}
.small-tital {
  color: #526574 !important;
  font-size: 14px !important;
  font-weight: bold;
  text-align: center;
  margin-bottom: 10px;
}

.slide-section {
  text-align: center;
  margin: 0 auto;
}
.slide-section ul {
  padding: 30px 0 0 0;
  margin: 0;
  justify-content: space-between;
  display: flex;
}
.slide-section ul li:nth-child(1) {
  padding-left: 0;
}
.slide-section ul li:nth-child(3) {
  padding-right: 0;
}

.slide-section ul li {
  padding: 3px 5px;
  margin: 0;
  list-style: none;
  display: inline-block;
  cursor: pointer;
}
.slide-section ul li i {
  color: #fff;
  font-size: 26px;
}
.progress {
  background-color: transparent;
  border: 1px solid #fff;
  margin-top: 50px;
  height: 5px;
}
.progress-bar {
  background-color: #fff;
}
.answered {
  color: #ddd;
  font-size: 14px;
  text-align: right;
}
.psw-btn {
  background: #273969 !important;
  margin-bottom: 30px;
  color: #fff !important;
  border: 1px solid #273969 !important;
}
.psw-btn:hover {
  color: #273969;
  background: transparent;
}
.pswa-btn {
  background: #9c27b0 !important;
  margin-bottom: 30px;
  color: #fff !important;
  border: 1px solid #9c27b0;
}
.pswa-btn:hover {
  color: #9c27b0 !important;
  background: #fff !important;
}
.profile-img img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin-bottom: 25px;
}
.logout-btn {
  display: flex;
  align-items: center;
}
.lgt-img {
  width: 38px;
  height: 38px;
  border-radius: 50%;
  border: 1px solid #efefef;
  margin-right: 5px;
}
.btn-lgt {
  background: transparent;
  font-size: 12px;
}
.btn-check:focus + .btn,
.btn:focus {
  box-shadow: none;
}
.postque .que-section {
  padding: 0;
}
.postque .postque-bg {
  background: #273969;
  padding: 120px 110px 0px;
  height: 100%;
  min-height: 100vh;
  position: relative;
}
.postque .postque-title {
  color: #fff;
  font-weight: bold;
  font-size: 18px;
  text-align: left;
  margin-bottom: 15px;
  width: 100%;
}
.postque .ans-fill {
  display: inline-grid;
  padding: 50px 0 50px 0;
}
.postque .ans-fill i {
  padding: 0 5px 0 0px;
  position: relative;
  top: 5px;
  font-size: 20px;
  font-weight: normal;
  color: #fff;
}
.postque .ans-fill input {
  background: transparent;
  border: 0;
  color: #fff;
  border-bottom: 1px solid #c9cdd9;
  border-radius: 0;
  width: 100%;
}
.postque .list-que {
  padding: 0px 0 0;
  overflow-y: auto;
  background-size: cover;
  position: relative;
  background-position: center;
  bottom: 0;
  z-index: 0;
}
.postque .list-que ul {
  margin: 0;
  padding: 42px 100px 0 100px;
}
.postque .list-que ul li {
  padding: 8px 0;
  margin: 0;
  list-style: none;
  color: #30416f;
  font-size: 16px;
  display: block;
  cursor: pointer;
}
.postque .list-que ul li span {
  padding-left: 20px;
}
.postque .list-que ul li i,
.postque .list-que ul li svg {
  padding-right: 5px;
}
.postque .list-que ul li span.shadow {
  display: block;
  padding: 20px 15px;
  font-size: 20px;
  font-weight: bold;
}
.postque .list-que ul li.active {
  color: #8860d0;
}
.postque .next-section {
  position: absolute;
  left: 15px;
  top: 390px;
  transform: rotate(270deg);
  transform-origin: 0 0;
}
.postque .next-section ul {
  margin: 0;
  padding: 0;
}
.postque .next-section ul li {
  margin: 0;
  padding: 0;
  list-style: none;
  display: inline-block;
  font-size: 14px;
  color: #fff;
  opacity: 0.5;
}
.postque .next-section ul li:nth-child(1) {
  padding: 0 30px 0 0;
}
.postque .next-section ul li i {
  padding-right: 5px;
  padding-left: 5px;
}
.postque .next-section ul li a {
  color: #fff;
}

.postque .postquesect-title {
  color: #fff;
  font-weight: bold;
  font-size: 24px;
  text-align: left;
  margin-bottom: 30px;
}

.postque .postque-desc {
  color: #fff;
  font-size: 13px;
  text-align: left;
  margin-bottom: 30px;
}
.postque .postque-desc span {
  font-size: 13px;
  font-style: italic;
}
.postque .postquesect-title.shadow {
  display: block;
  padding: 20px 15px;
  font-size: 20px;
  font-weight: bold;
  text-transform: capitalize;
}
.reviewsection_title {
  font-weight: bold;
  color: #145da0;
  text-transform: capitalize;
}
.reviewsection_quetitle {
  font-weight: bold;
  background-color: #b1d4e038;
  color: #0c2d48;
  font-weight: 500;
  font-size: 15px;
  letter-spacing: 0.4px;
}
.reviewsection_que {
  font-weight: 600;
  color: #0c2d48;
  font-size: 15px;
  letter-spacing: 0.4px;
  text-align: justify;
}
.payment-section {
}
.pay-main-tital {
  color: #273969;
  margin-top: 15px;
  font-weight: 600;
}
.pay-sub-tital {
  color: #b5b5b5;
  margin-top: 15px;
  font-weight: 400;
  font-size: 14px;
}
.payment-note {
  background: #f9f9f9;
  border: 1px solid #9c27b0;
  border-radius: 5px;
}
.payment-note p {
  font-size: 14px;
  color: #b5b5b5;
  margin: 0;
  padding: 15px;
}
.select-payment {
  display: flex;
  align-self: center;
  align-items: center;
  justify-content: space-between;
}
.select-payment .pcard {
  width: 200px;
}
.payment-method {
  background: #f9f9f9;
  border: 1px solid #9c27b0;
  border-radius: 5px;
  margin: 30px 0;
  padding: 30px 15px;
}
.bg-white {
  /*background: #fff;*/
}
.payment-method h3,
.order-section h3 {
  color: #273969;
  margin: 0px 0 20px 0;
}
.payment-method input.custom-input,
.discount input.custom-input {
  height: 45px;
}
.order-section {
  background: #f9f9f9;
  margin: 30px 0;
  padding: 30px;
}
.order-total ul {
  margin: 0;
  padding: 0;
}
.order-total ul li {
  margin: 0;
  padding: 20px 0;
  list-style: none;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #9c27b0;
}
.order-total ul li:first-child {
  border-top: 1px solid #9c27b0;
}
.order-total ul li:last-child {
  border-bottom: 0;
}
.total-label,
.total-price {
  color: #273969;
  font-weight: 600;
  font-size: 18px;
  margin-bottom: 0;
  text-transform: capitalize;
}
.discount {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.btn-apply {
  background: #273969;
  padding: 11px 35px;
  margin-left: 15px;
  border: 0;
  border-radius: 5px;
  color: #fff;
}
.purchase-section .form-check input.form-check-input {
  float: none;
  width: 18px;
  height: 18px;
}
.purchase-section .form-check .form-check-label {
  color: #b5b5b5;
  font-weight: 600;
  letter-spacing: 1px;
}
.btn-purchase {
  background: #ff5722;
  padding: 11px 35px;
  margin: 30px 0;
  font-weight: 500;
  letter-spacing: 1px;
  border: 0;
  border-radius: 5px;
  color: #fff;
}
.btn-purchase:disabled,
.btn-purchase[disabled] {
  background-color: #cccccc;
  color: #666666;
}
.purchase-section {
  margin: 30px 0 0 0;
}
.payment-op1 {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  padding: 10px 15px;
  margin-top: 30px;
}
.purple {
  color: #273969;
}
.payment-op1 h3 {
  font-size: 18px;
  color: #273969;
}
.payment-op1 h6 {
  font-size: 14px;
  color: #273969;
}
.amount-note {
  background: #f9f9f9;
  border: 1px solid #9c27b0;
  border-radius: 5px;
  margin-top: 20px;
  padding: 15px 15px 0 15px;
}
.amount-note p {
}
.quote {
  background: #f9f9f9;
  border: 1px solid #9c27b0;
  border-radius: 5px;
  padding: 15px 15px 15px 15px;
  max-width: 200px;
  max-height: 250px;
  margin: 0 auto;
  margin-top: 30px;
  text-align: center;
}
.quote h6 {
  font-size: 16px;
  color: #273969;
}
.quote p {
  font-size: 30px;
  font-weight: bold;
  color: #273969;
}
.quote a {
  color: #9c27b0;
  font-weight: 500;
  font-size: 16px;
  text-decoration: none;
  letter-spacing: 1px;
  border: 1px solid;
  padding: 5px 10px;
  background: #fff;
  border-radius: 3px;
  text-transform: uppercase;
}
.quote a:hover {
  text-decoration: none;
  color: #273969;
}
.thanks-section {
  max-width: 650px;
  margin: 0 auto;
  margin-top: 100px;
  text-align: center;
}
.thanks-section h2 {
  color: #273969;
  font-weight: 600;
}
.thank-box {
  background: #f9f9f9;
  border: 1px solid #9c27b0;
  border-radius: 5px;
  padding: 15px 15px 15px 15px;
  margin-top: 30px;
}
.thank-box p:first-child {
  color: #9c27b0;
  font-weight: 500;
}

.custom-radio-btn {
  border: 1px solid #bbb9b9;
  width: 100%;
  padding: 8px;
  margin-bottom: 8px;
  border-radius: 4px;
  min-width: 300px;
}

@media only screen and (max-width: 600px) {
  body {
    height: inherit;
  }
  .header-logo img {
    width: 70px;
  }
  .que-section {
    padding: 15px 0;
  }
  .justify-content-end {
    justify-content: center !important;
  }
  .call {
    padding: 15px 0 0 0;
  }
  .que-section p {
    text-align: center;
  }

  .custom-radio-btn {
    border: 1px solid #bbb9b9;
    min-width: 240px;
    padding: 8px;
    margin-bottom: 8px;
    border-radius: 4px;
  }
}

.attent {
  color: #273969;
  font-weight: bold;
  font-size: 16px;
  text-transform: capitalize;
}
.box {
  margin-top: 15px;
}
.postque {
  padding-top: 0px;
}
.sticky {
  position: sticky;
  top: 0;
}
.main-title {
  background: #ee6c4d;
  color: #fff;
  display: block;
  padding: 10px 10px;
  font-size: 20px;
  font-weight: bold;
  text-transform: capitalize;
  border-radius: 5px;
  margin-bottom: 25px;
}
.list-group-item {
  border: 0;
  font-weight: 500;
  font-size: 15px;
  letter-spacing: 0.4px;
}
.card-footer {
  display: flex;
  justify-content: space-between;
}
