body {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}
.react-tel-input .form-control:focus {
  border-color: #3f51b5;
  box-shadow: 0 0 0 1px #3f51b5;
}
.error {
  color: #f44336;
  margin-left: 14px;
  font-size: 0.75rem;
  margin-top: 3px;
  text-align: left;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  line-height: 1.66;
  letter-spacing: 0.03333em;
}
.creBtnEdit {
  width: 20%;
  margin-bottom: 5px !important;
}

.MuiTypography-colorTextSecondary {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

nav {
  width: auto !important;
  cursor: pointer;
  float: left;
}
.creBtnEdit:hover {
  background-color: transparent !important;
  width: 85%;
  margin-bottom: 5px !important;
}
.lgt-img {
  width: 38px;
  height: 38px;
  border-radius: 50%;
  border: 1px solid #efefef;
  margin-right: 15px;
}
.payment-op1 {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  padding: 10px 15px;
}
.que-option .form-check-label {
  color: #273969;
  align-self: center;
  cursor: pointer;
}
.que-option .form-check-label {
  color: #273969;
  align-self: center;
  cursor: pointer;
}
.payment-op1 h3 {
  font-size: 18px;
  color: #273969;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}

.payment-op1 h6 {
  font-size: 14px;
  color: #273969;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}
.que-option {
  padding-top: 20px;
  padding-bottom: 10px;
}
.que-list {
  padding: 15px 0 15px 0;
  margin: 0;
}
.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem;
}
.que-list li {
  list-style: none;
  display: block;
  margin: 0;
  padding: 7px 0;
  color: #283a6a;
  font-size: 16px;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}

.form-check-label {
  margin-bottom: 0;
}
.order-total ul li:first-child {
  border-top: 1px solid #9c27b0;
}
.order-total ul li {
  margin: 0;
  padding: 20px 0;
  list-style: none;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #9c27b0;
}
.btn-apply {
  background: #273969;
  padding: 11px 35px;
  margin-left: 15px;
  border: 0;
  border-radius: 5px;
  color: #fff;
}
.purple {
  padding-top: 10px;
  color: #273969;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}
/*post qusestionary*/
.list-que {
  padding: 0px 20px 0;
  overflow-y: auto;
}
.list-que ul li span {
  padding-left: 20px;
}
.list-que ul li {
  padding: 8px 0;
  margin: 0;
  list-style: none;
  color: #263238;
  font-size: 16px;
  display: block;
  cursor: pointer;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}
.list-que ul li i,
.postque .list-que ul li svg {
  padding-right: 5px;
}
.list-que ul li span.shadow {
  display: block;
  padding: 20px 15px;
  font-size: 20px;
  font-weight: bold;
}
.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}
.postque-bg {
  padding: 0px 0px 0px;
}

.quote {
  text-align: center;
  border: 1px solid #3f51b5;
  padding: 30px 0px 30px 0px;
  border-radius: 4px;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  margin-left: 10px;
}

.quote h3 {
  padding-bottom: 5px;
}

.que-option .form-check {
  margin-bottom: 20px;
}

.amount-note {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  background: #f9f9f9;
  border: 1px solid #9c27b0;
  border-radius: 5px;
  margin-top: 20px;
  padding: 15px;
  font-size: 14px;
}

.select-payment {
  display: flex;
  align-self: center;
  align-items: center;
  justify-content: space-between;
}

.Credit-card {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.discount-box {
  display: flex;
  align-items: center;
}

.thank-box {
  background: #f9f9f9;
  border: 1px solid #9c27b0;
  border-radius: 5px;
  padding: 15px 15px 15px 15px;
  margin: 30px 0px;
  text-align: center;
}

.thank-box h2 {
  padding-bottom: 10px;
}

.pq-form h3 {
  font-size: 16px;
  padding-bottom: 10px;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}

.total-label {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}

.payment-details-below h2 {
  font-size: 28px;
  padding-bottom: 10px;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}

.postque-title {
  font-size: 18px;
  padding-bottom: 10px;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}

.que-title {
  padding-bottom: 15px;
  font-size: 24px;
}

.reviewsection_title {
  background-color: rgba(0, 0, 0, 0.03);
}

.reviewsection_quetitle {
  background-color: #b1d4e038;
}
.tab-scroll {
  overflow-x: auto !important;
}

.psn-btn {
  display: flex;
  justify-content: space-between;
  padding: 20px 0px;
}

.answered {
  padding-top: 10px;
}

.bc-btn {
  display: flex;
  justify-content: space-between;
  padding: 15px;
}

.max-min-btn {
  display: flex;
  align-items: center;
}

.agreetbtn {
  display: flex;
  align-items: center;
  padding: 0px 14px;
}

.agreetbtn button {
  margin: 0px 10px 10px 0px;
}

.ex-input {
  padding-right: 10px;
}

.cvv-input {
  padding-left: 10px;
}

.postque-desc {
  margin-top: 20px;
  margin-bottom: 20px;
}

.hd-and-btn {
  display: flex;
  justify-content: space-between;
}

.ta-flex {
  display: flex;
}

.selectControl {
  min-width: 45% !important;
  margin: 15px 0px 15px 0px !important;
}

.statusdocformselectControl {
  min-width: 100% !important;
  margin: 15px 0px 15px 0px !important;
}

.selectControlfield {
  min-width: 45% !important;
  margin: 15px 0% 15px 2% !important;
}

.cm-footer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #fff;
  border-top: 1px solid #dee2e6;
  color: #869099;
  padding: 1rem;
}
.logout-btn .dropdown .dropdown-toggle {
  margin-bottom: 0;
  margin-left: 10px;
}

.profilr-box {
  position: relative;
  width: 100px;
  height: 100px;
  border: 1px solid #c0c0c0;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pro-upload {
  position: absolute;
  right: 0px;
  bottom: 5px;
}
.review_rating .MuiInputLabel-root {
  padding-right: 10px;
  font-weight: bold;
}
.review_rating {
  display: flex;
  align-items: center;
}
.input-file {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  border-width: 1px;
  border-radius: 5px;
  border-style: dashed;
  background-color: #ffffff00;
  color: #0084fffd;
  outline: none;
  transition: border 0.24s ease-in-out;
  padding: 10px;
}
.dragStart {
  color: #8c00ffa8;
}
.dragFailed {
  color: #ff0000a8;
}
.input-file-preview img {
  height: 100px;
  width: 100px;
  margin-bottom: 10px;
  border: 1px dotted #fff;
  border-radius: 5px;
  object-fit: contain;
  margin-right: 20px;
}

.main-body {
  /* height: 80%; */
  /* padding-top: 65px; */
  /* padding-bottom: 65px; */
  overflow-y: auto;
}
.theme-bg-color {
  background: #526574;
  padding: 3%;
  padding-bottom: 100px;
}
.scroll-md {
  max-width: 100%;
  /* height: 91vh; */
  overflow-y: auto;
}
.scroll-md::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #f5f5f5;
}
.scroll-md::-webkit-scrollbar {
  width: 8px;
  background-color: #f5f5f5;
}
.scroll-md::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #808080;
}
.actionIcon .MuiButton-root {
  min-width: 50px;
}
.postque .MuiInputBase-root {
  color: #fff !important;
}
/* .postque .MuiInput-underline::before,.postque .MuiInput-underline:hover:not(.Mui-disabled):before
{
  border-bottom-color:#fff ;
} */
.postque .react-tel-input .form-control {
  background: #ffffff00;
}
.postque .MuiFormLabel-root,
.postque .MuiFormLabel-root.Mui-focused {
  color: #fff !important;
}
.postque .react-tel-input .form-control,
.postque .react-tel-input .form-control:focus {
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
  border-color: #fff !important;
  border-radius: 0px !important;
  color: #ccc !important;
  box-shadow: none;
}
.postque .react-tel-input .special-label {
  display: none;
}
.postque .react-tel-input .selected-flag .arrow.up {
  border-top: none;
  border-bottom: 4px solid #fff;
}
.postque .react-tel-input .selected-flag .arrow {
  border-top: 4px solid #fff;
}
.postque .MuiInputLabel-root {
  color: #ccc;
}
.postque .MuiSvgIcon-root {
  color: #ccc !important;
}
.postque .custom-radio-btn span {
  color: #fff;
}
.input-file {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  border-width: 1px;
  border-radius: 5px;
  border-style: dashed;
  background-color: #9c9c9c50;
  color: #ffffffa8;
  outline: none;
  transition: border 0.24s ease-in-out;
  padding: 10px;
}
.postque .MuiInput-underline:hover:not(.Mui-disabled):before,
.postque .MuiInput-underline:before,
.postque .MuiInput-underline:after {
  border-bottom: 2px solid #fff;
}

.MuiAccordionDetails-root .MuiGrid-root.dropzone.input-file p,
.MuiAccordionDetails-root .MuiGrid-root.dropzone.input-file h6 {
  color: #000;
}
.MuiAccordionDetails-root .MuiGrid-root.dropzone.input-file.dragFailed p,
.MuiAccordionDetails-root .MuiGrid-root.dropzone.input-file.dragFailed h6 {
  color: #ff0000a8;
}
/*Create design same as front side lotus view*/

.MuiButton-containedPrimary {
  background-color: #d8b34f !important ;
}

.MuiAppBar-colorPrimary {
  background-color: #22696d !important ;
}

.MuiAvatar-colorDefault {
  background-color: #22696d;
}
.MuiContainer-root .MuiButton-outlinedPrimary {
  color: #000000;
  border: 1px solid #eb9d2f8c;
}

.MuiContainer-root .MuiButton-outlinedPrimary:hover {
  border: 1px solid#22696d;
  /* background-color: rgba(63, 81, 181, 0.04); */
}
.MuiContainer-root .MuiAvatar-colorDefault {
  background-color: #22696d;
}

.makeStyles-root-641,
.MuiBreadcrumbs-root .MuiTypography-colorPrimary {
  color: #22696d !important;
}

.MuiContainer-root .MuiButton-textPrimary {
  color: #22696d;
}

.MuiTabs-root .MuiTabs-indicator {
  background-color: #22696d;
}

.MuiOutlinedInput-notchedOutline {
  border-color: #22696d !important;
  color: #22696d !important;
}

.MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline {
  border-color: #f44336 !important;
}

.MuiFormLabel-root,
.MuiFormLabel-root.Mui-focused {
  color: #22696d !important;
}

.MuiFormLabel-root.Mui-error,
.MuiFormLabel-root.Mui-error.Mui-focused,
.MuiFormLabel-root.Mui-focused.Mui-error input::placeholder {
  color: #f44336 !important;
}

.special-label {
  color: #22696d;
}

.react-tel-input .form-control {
  border: 1px solid #22696d;
  color: #22696d;
}

.MuiFormControl-root .MuiFormLabel-root .Mui-error {
  color: #f44336;
}

.react-tel-input .form-control:focus {
  color: #000000;
  border: 1px solid #22696d;
}

.react-tel-input .form-control:focus {
  border-color: #22696d;
  box-shadow: 0 0 0 1px #22696d;
}

.react-tel-input .form-control:hover {
  border-color: #22696d;
}

.react-tel-input .form-control.invalid-number {
  border: 1px solid #f44336;
  color: #f44336;
  box-shadow: 0 0 0 1px #f44336;
}

.MuiGrid-root .MuiButton-containedSecondary {
  background-color: #d8b34f;
}

.MuiGrid-root .MuiButton-containedSecondary:hover {
  background-color: #d8b34f;
}

.MuiGrid-root .MuiSwitch-colorPrimary.Mui-checked {
  color: #d8b34f;
}

.MuiTable-root .MuiCheckbox-colorPrimary.Mui-checked {
  color: #22696d;
}

/* .MuiTableHead-root .MuiButton-root {
  color: #22696d;
} */

/* .MuiButton-label {
  color: #fff;
} */

/* .MuiTableRow-root .MuiTableCell-head {
  color: #22696d;
} */

.MuiListItemSecondaryAction-root .MuiRadio-colorSecondary.Mui-checked {
  color: #22696d;
}
.MuiGrid-root .MuiCheckbox-colorSecondary.Mui-checked {
  color: #22696d;
}
/* .MuiTableCell-root .MuiButtonBase-root{
  color: #22696d;
} */
.MuiInput-underline:after {
  border-bottom: 2px solid #22696d !important;
}

.MuiCheckbox-colorSecondary.Mui-checked {
  color: #22696d !important;
}
.ColorPicker-MuiInput-underline:after {
  border-bottom: 2px solid #22696d !important;
}
.MuiTypography-colorInherit {
  color: #22696d !important;
}
.MuiDialogActions-root .MuiButton-label {
  color: #22696d;
}

/* .MuiTableHead-root .MuiTableSortLabel-root.MuiTableSortLabel-active {
  color: #22696d;
} */

.MuiBox-root .MuiSwitch-colorSecondary.Mui-checked {
  color: #d8b34f;
}

.css-vqmohf-MuiButtonBase-root-MuiRadio-root.Mui-checked {
  color: #22696d !important;
}

.MuiRadio-colorPrimary.Mui-checked {
  color: #22696d !important;
}

.MuiCheckbox-colorPrimary.Mui-checked {
  color: #22696d !important;
}
.MuiAvatar-circular .MuiAvatar-img {
  object-fit: contain;
}
.linkclass {
  color: #22696d;
}
.theme-bg-color .btn-color {
  color: #fff;
  background-color: #d8b34f;
  border: 1px solid #d8b34f;
}
.theme-bg-color .btn-color:hover {
  color: #fff;
  background-color: #ce6632;
  border: 1px solid #ce6632;
}
.theme-bg-color .btn-color:disabled {
  background-color: #d5d5dd;
  border: 1px solid #d5d5dd;
  color: #000;
}
.dynamic-body:after {
  content: "";
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: -1;
}
.dynamic-body .MuiFormLabel-root,
.dynamic-body .MuiFormLabel-root.Mui-focused {
  color: rgba(0, 0, 0, 0.6) !important;
}
.dynamic-body .MuiInput-underline:after {
  border-bottom: 2px solid rgb(0, 0, 0) !important;
}
.dynamic-body .MuiRadio-colorPrimary.Mui-checked {
  color: #000 !important;
}
.postque .list-que:after {
  background-color: rgba(0, 0, 0, 0.5);
  bottom: 0;
  content: "";
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: -1;
}
.theme-bg-color .MuiInput-underline:after {
  border-bottom: 2px solid #fff !important;
}

/* status page admin */

.status-form {
  width: 100%;
}
.status-main {
  display: flex;
}
.status-title {
  width: 20%;
  padding: 5px 0px;
}
.status-row {
  padding: 5px 0px;
  width: 80%;
  display: flex;
  justify-content: space-between;
}
.deceased-name {
  margin-right: 20px;
  /* width: 80%; */
}
.status-deceased-main{
  width: fit-content !important;
}

.custom-mui-table-main th > span{
  width: 200px;
}



/* Responsive Css */
@media (min-width: 1151px) and (max-width: 1280px){
  .status-f p {
    font-size: 14px;
  }
  .status-f span {
    font-size: 14px;
  }
}



@media (min-width: 1441px){
  .status-title {
    width: 15%;
    padding: 5px 0px;
  }
  .status-row {
    padding: 5px 0px;
    width: 85%;
    display: flex;
    justify-content: space-between;
  }
}

@media (max-width: 1440px) {
  .status-row{
    flex-wrap: wrap;
    justify-content: start;
  }
  .status-row div {
    padding: 0px 15px 0px 0px;
}
}

@media (max-width: 1199px) {
  .agreetbtn {
    display: block;
    text-align: center;
  }
  .agreetbtn button {
    margin: 0px 10px 10px 0px;
  }
  .payment-details-below h2 {
    font-size: 22px;
  }
  .quote {
    margin: 10px 0px 0px 0px;
  }
}

@media (max-width: 991px) {
}

@media (min-width: 768px) {
}

@media (max-width: 767px) {
}

@media (max-width: 575px) {
  .logo_main {
    width: auto !important;
    height: 26px !important;
  }
  .avatar_text {
    display: none;
  }
  .psn-btn {
    display: flex;
    flex-direction: column;
  }
  .psn-btn button {
    margin-bottom: 10px;
  }
  .que-title {
    padding-bottom: 15px;
    font-size: 20px;
  }
  .postque-title {
    font-size: 16px;
  }
  .psn-btn {
    display: flex;
    justify-content: space-between;
    padding: 20px 0px;
  }
  .list-que ul li span.shadow {
    font-size: 14px;
    padding: 10px 15px;
  }
  .quote {
    margin: 10px 0px 0px 0px;
  }
  .max-min-btn {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .agreetbtn {
    display: block;
    text-align: center;
  }
  .agreetbtn button {
    margin-bottom: 10px;
  }
  .payment-details-below h2 {
    font-size: 20px;
  }
  .hd-and-btn {
    display: block;
  }
  .hd-and-btn h3 {
    margin-bottom: 10px;
  }
  .selectControl {
    min-width: 100% !important;
    margin: 15px 0px 15px 0px !important;
  }
  .selectControlfield {
    min-width: 100% !important;
    margin: 15px 0% 15px 2% !important;
  }
}
